import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es__header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es__header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es__header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
                  {/*<li>
                    <a className="es__header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/know_the_sun/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/know_the_sun/">UK</a>
                      </li>
                      <li>
                        <a href="/es/conozca-el-sol/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/sobre_o_sol/">PT</a>
                      </li>
                      <li>
                        <a href="/it/know_the_sun/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/know_the_sun/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="kts-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x337px_KnowTheSun.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/know_the_sun_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="kts-page container">
              <div className="kts-page__intro">
                <h1>ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ</h1>
                <h3>Ο ΟΔΗΓΟΣ ΜΑΣ ΓΙΑ ΝΑ ΑΠΟΛΑΜΒΑΝΕΤΕ ΤΟΝ ΗΛΙΟ ΜΕ ΑΣΦΑΛΕΙΑ</h3>
                <p>
                  Όλα τα αντηλιακά προϊόντα PIZ BUIN<sup>®</sup> περιέχουν
                  προηγμένη προστασία κατά των ακτίνων UVA/UVB για να
                  διασφαλιστεί ότι λαμβάνετε αποτελεσματική αντηλιακή προστασία.
                  Ωστόσο, πιστεύουμε ότι πάντα μπορούμε να μάθουμε περισσότερα
                  για τον ήλιο και τις πιθανές επιπτώσεις που ενδέχεται να έχει
                  σε εμάς. Παρακάτω παραθέτουμε ορισμένα σημαντικά στοιχεία για
                  τον ήλιο και πώς μπορείτε να τον απολαύσετε καλύτερα και
                  υπεύθυνα:
                </p>
              </div>
              <section id="content">
                <div className="innerContent">
                  <ul className="ktsBlocks">
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">Ο ΗΛΙΟΣ</h2>
                        <p className="toggle__sub-title">
                          Η ακτινοβολία UVA και UVB είναι γνωστοί όροι, αλλά τι
                          σημαίνουν και πώς μας επηρεάζουν; Μάθετε εδώ..{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul id="container_thesun">
                        <li>
                          <div
                            id="ccm-layout-wrapper-5401"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-4-1"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-1 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-4-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  <h2>ΑΚΤΙΝΟΒΟΛΙΑ</h2>
                                  <p>
                                    Ο ήλιος παρέχει στη Γη ζωογόνο ενέργεια και
                                    είναι ο βασικός παράγοντας για τον καθορισμό
                                    του κλίματος και των καιρικών συνθηκών στον
                                    πλανήτη. Ο ήλιος όχι μόνο στέλνει ορατό φως
                                    στη γη, αλλά και αόρατη ακτινοβολία που
                                    μπορεί να έχει αρνητικές επιπτώσεις στην
                                    υγεία μας, εάν ληφθεί σε ιδιαίτερα υψηλές
                                    δόσεις.
                                  </p>
                                  <p>
                                    Η ηλιακή ακτινοβολία αποτελείται από μια
                                    ευρεία ποικιλία ακτίνων. Ορισμένες ακτίνες
                                    δεν φτάνουν στη Γη, καθώς εμποδίζονται από
                                    το στρώμα του όζοντος. Η ακτινοβολία που
                                    φτάνει στη Γη αποτελείται από τις ορατές,
                                    τις υπέρυθρες (IR) και τις υπεριώδεις (UV)
                                    ακτίνες. Η υπεριώδης ακτινοβολία διαιρείται
                                    περαιτέρω σε ακτίνες UVA, UVB και UVC,
                                    ανάλογα με το μήκος κύματός τους. Οι ακτίνες
                                    UVC εμποδίζονται επίσης από το στρώμα του
                                    όζοντος.
                                  </p>
                                  <div
                                    id="blockStyle1685TheSunLayout1Cell162"
                                    className=" ccm-block-styles"
                                  >
                                    <img
                                      border="0"
                                      className="ccm-image-block"
                                      alt
                                      src="/assets/images/Radiation_graph_GR.png"
                                      width="640"
                                      height="380"
                                    />
                                  </div>
                                  <h2>ΟΡΑΤΟ ΦΩΣ</h2>
                                  <p>
                                    Το ορατό φως αποτελείται από όλα τα χρώματα
                                    που βλέπουμε με γυμνό μάτι.
                                  </p>
                                  <p> </p>
                                  <h2>ΥΠΕΡΥΘΡΕΣ (IR) ΑΚΤΙΝΕΣ</h2>
                                  <p>
                                    Οι υπέρυθρες (IR) ακτίνες μεταφέρουν θερμική
                                    ενέργεια, δίνοντάς μας τη δυνατότητα να
                                    αισθανόμαστε τη θερμότητα.
                                  </p>
                                  <p> </p>
                                  <h2>ΥΠΕΡΙΩΔΕΙΣ (UV) ΑΚΤΙΝΕΣ</h2>
                                  <p>
                                    Οι υπεριώδεις (UV) ακτίνες είναι αόρατες και
                                    διαιρούνται σε ακτίνες UVA, UVB και UVC.
                                    ακτίνες UVA προκαλούν μακροπρόθεσμες βλάβες
                                    στο δέρμα, όπως καρκίνο και πρόωρη γήρανση
                                    του δέρματος, ενώ οι ακτίνες UVB είναι η
                                    κύρια αιτία του ηλιακού εγκαύματος. Περίπου
                                    10 φορές περισσότερες ακτίνες UVA φθάνουν
                                    στην επιφάνεια της Γης σε σχέση με τις
                                    ακτίνες UVB. Οι ακτίνες UVC απορροφώνται από
                                    την ατμόσφαιρα της γης και δεν επηρεάζουν το
                                    δέρμα.
                                  </p>
                                  <p>
                                    Η ένταση της ηλιακής ακτινοβολίας εξαρτάται
                                    από διάφορους παράγοντες, όπως την εποχή του
                                    έτους, την ώρα της ημέρας, το γεωγραφικό
                                    πλάτος, το υψόμετρο, τις ανακλάσεις από το
                                    έδαφος και τις καιρικές συνθήκες. Για
                                    παράδειγμα, ο ήλιος είναι φωτεινότερος κατά
                                    τις μεσημεριανές ώρες, ενώ σε μεγάλα
                                    υψόμετρα απορροφάται λιγότερη υπεριώδης
                                    ακτινοβολία από τη λεπτότερη ατμόσφαιρα.
                                  </p>
                                  <p>Γνωρίζατε ότι;</p>
                                  <p> </p>
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="ccm-layout-wrapper-5402"
                            className="ccm-layout-wrapper"
                          >
                            <div
                              id="ccm-layout-thesun-8-3"
                              className="ccm-layout ccm-layout-table  ccm-layout-name-TheSun-Layout-3 "
                            >
                              <div className="ccm-layout-row ccm-layout-row-1">
                                <div
                                  className="ccm-layout-8-col-1 ccm-layout-cell ccm-layout-col ccm-layout-col-1 first"
                                  style={{
                                    width: "100%"
                                  }}
                                >
                                  &nbsp;
                                </div>
                                <div className="ccm-spacer" />
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2285TheSun83"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_01.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Το <span className="orange">80%</span> των
                                  υπεριώδων ακτίνων μπορούν να διαπεράσουν τα
                                  σύννεφα.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2286TheSun91"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_02.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Το καθαρό χιόνι αντανακλά μέχρι και το{" "}
                                  <span className="orange">80%</span> του
                                  συνόλου των υπεριώδων ακτίνων.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2297TheSun92"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_03.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Το <span className="orange">60%</span> των
                                  υπεριώδων ακτίνων παρατηρείται μεταξύ{" "}
                                  <span className="orange">10</span> π.μ. και{" "}
                                  <span className="orange">2</span> μ.μ.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2288TheSun93"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_04.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Η υπεριώδης ακτινοβολία αυξάνεται κατά{" "}
                                  <span className="orange">4%</span> ανά{" "}
                                  <span className="orange">300</span> μέτρα
                                  υψόμετρο.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2289TheSun94"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_05.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Η σκιά μπορεί να μειώσει την έκθεση στην
                                  υπεριώδη ακτινοβολία κατά{" "}
                                  <span className="orange">50%</span> ή και
                                  περισσότερο.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2290TheSun95"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_06.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Σε μισό μέτρο κάτω από το νερό, η υπεριώδης
                                  ακτινοβολία εξακολουθεί να είναι{" "}
                                  <span className="orange">40%</span> έντονη όσο
                                  και στην επιφάνεια.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2291TheSun96"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_07.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Η λευκή άμμος αντανακλά έως και το{" "}
                                  <span className="orange">15%</span> της
                                  υπεριώδους ακτινοβολίας.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div
                            id="blockStyle2292TheSun97"
                            className=" ccm-block-styles"
                          >
                            <div className="ktsImageFloatingBlock">
                              <div className="imgWrapper">
                                <img
                                  src="/assets/images/kts_img_320_08.png"
                                  width="320"
                                  height="325"
                                  alt
                                />
                              </div>
                              <div className="textWrapper">
                                <p>
                                  Το νερό αντανακλά περίπου το{" "}
                                  <span className="orange">25%</span> της
                                  υπεριώδους ακτινοβολίας.
                                </p>
                                <p className="footNote">
                                  Πηγή: Global Solar UV Index, WHO
                                </p>
                                <div> </div>
                              </div>
                            </div>
                          </div>
                          <h2>ΕΠΙΠΤΩΣΕΙΣ ΤΟΥ ΗΛΙΟΥ</h2>
                          <p>
                            Ο ήλιος καθιστά δυνατή τη ζωή πάνω στη Γη, ωστόσο
                            μπορεί να έχει επιβλαβείς επιδράσεις – ιδιαίτερα
                            χωρίς επαρκή αντηλιακή προστασία.
                          </p>
                          <p> </p>
                          <h2>ΑΜΕΣΕΣ ΕΠΙΔΡΑΣΕΙΣ</h2>
                          <p>
                            <strong>Άμεση μελάγχρωση (μαύρισμα)</strong>
                            <br />Η πρώτη επίδραση που σχετίζεται με το χρώμα
                            και είναι το αποτέλεσμα της έκθεσης στον ήλιο είναι
                            γνωστή ως "άμεση μελάγχρωση" ή χρώση, η οποία
                            εμφανίζεται κατά την έκθεση στον ήλιο και
                            εξαφανίζεται εντός 2 ωρών. Η άμεση μελάγχρωση που
                            προκαλείται από τις ακτίνες UVA και το ορατό φως δεν
                            προστατεύει από το ηλιακό έγκαυμα.
                          </p>
                          <p>
                            <strong>Επίμονη μελάγχρωση (PPD)</strong>
                            <br />Η επίμονη μελάγχρωση εμφανίζεται κατά τη
                            διάρκεια της έκθεσης τον ήλιο και διατηρείται για
                            αρκετές ημέρες. Δεν προστατεύει.
                          </p>
                          <p> </p>
                          <h2>ΕΠΙΒΡΑΔΥΝΟΜΕΝΕΣ ΕΠΙΔΡΑΣΕΙΣ</h2>
                          <p>
                            <strong>Ηλιακό έγκαυμα</strong>
                            <br />
                            Εάν δεν προστατεύεστε από τις υψηλές δόσεις
                            υπεριώδους ακτινοβολίας, οι ακτίνες αυτές θα
                            αρχίσουν να διαπερνούν τις στιβάδες του δέρματος.
                            Τελικά, παρατηρείται διαστολή και διαρροή υγρού από
                            τα αιμοφόρα αγγεία που βρίσκονται κάτω από την
                            επιδερμίδα, προκαλώντας φλεγμονή και ερυθρότητα. Το
                            ηλιακό έγκαυμα εμφανίζεται συνήθως 2-6 ώρες μετά την
                            έναρξη της έκθεσης, εντείνεται σε διάστημα 24-36
                            ωρών και εξαφανίζεται μετά από 3-5 ημέρες.
                          </p>
                          <p>
                            <strong>
                              Μαύρισμα (επιβραδυνόμενη μελάγχρωση)
                            </strong>
                            <br />
                            Το μαύρισμα αρχίζει 3-5 ημέρες μετά την έκθεση στον
                            ήλιο, όταν τα ειδικά κύτταρα του δέρματος, τα
                            μελανοκύτταρα, αρχίζουν να συνθέτουν μελανίνη, τη
                            φυσική χρωστική ουσία μαυρίσματος του σώματος. Στη
                            συνέχεια, η μελανίνη μεταφέρεται στα γειτονικά
                            κύτταρα που ονομάζονται κερατινοκύτταρα, τα οποία
                            αποτελούν τον κύριο τύπο κυττάρου της επιδερμίδας.
                            Σε αντίθεση με την άμεση μελάγχρωση, το μαύρισμα
                            συμβάλλει στην προστασία από τις επιβλαβείς
                            επιδράσεις της υπεριώδους ακτινοβολίας. Το μαύρισμα
                            εξασθενεί σταδιακά, αλλά να θυμάστε ότι οι άνθρωποι
                            με φυσικό μαύρισμα πρέπει επίσης να χρησιμοποιούν
                            αντηλιακό, καθώς το μαύρισμα δεν προσφέρει επαρκή
                            προστασία από την έκθεση στην υπεριώδη ακτινοβολία.
                          </p>
                          <p> </p>
                          <h2>ΜΑΚΡΟΠΡΟΘΕΣΜΕΣ ΕΠΙΔΡΑΣΕΙΣ</h2>
                          <p>
                            <strong>Γήρανση του δέρματος</strong>
                            <br />Η έκθεση στον ήλιο διαδραματίζει σημαντικό
                            ρόλο στη διαδικασία γήρανσης του δέρματος. Έως και
                            το 90% όλων των ορατών σημαδιών γήρανσης αποδίδεται
                            στις βλαβερές συνέπειες της έκθεσης στον ήλιο.
                          </p>
                          <p>
                            <strong>
                              Καρκίνος του δέρματος (κακοήθες μελάνωμα)
                            </strong>
                            <br />Ο καρκίνος του δέρματος ή το κακοήθες
                            μελάνωμα, φαίνεται ότι προκαλείται από βλάβη στη
                            δομή του DNA των κυττάρων του δέρματος που
                            εκτίθενται στον ήλιο – με ποσοστό μόλις το 5% όλων
                            των καρκινωμάτων να εμφανίζονται σε περιοχές του
                            δέρματος που δεν έχουν εκτεθεί στον ήλιο. Η βλάβη σε
                            αυτά τα κύτταρα του δέρματος οδηγεί στην ανάπτυξη
                            εκφυλισμένων κυττάρων που με τη σειρά τους οδηγούν
                            σε καρκίνο. Στην Ευρώπη, ο καρκίνος του δέρματος
                            είναι πολύ πιο διαδεδομένος στο βόρειο τμήμα, όπου
                            το δέρμα των ανθρώπων τείνει να περιέχει λιγότερη
                            μελάγχρωση.
                          </p>
                          <p> </p>
                          <h2>ΤΥΧΑΙΕΣ ΕΠΙΔΡΑΣΕΙΣ</h2>
                          <p>
                            <strong>Μη ανεκτικότητα στον ήλιο</strong>
                            <br />Ο πιο κοινός τύπος δερματικού εξανθήματος που
                            προκαλείται από τον ήλιο είναι το καλοήθες θερινό
                            εξάνθημα εκ φωτός (BSLE). Ιδιαίτερα συχνό μεταξύ των
                            νέων γυναικών, το BSLE εμφανίζεται ως μικρές,
                            κνησμώδεις βλατίδες στο λαιμό, στο άνω μέρος του
                            θώρακα και στο πίσω μέρος των βραχιόνων. Το εξάνθημα
                            εμφανίζεται μέσα σε λίγες ώρες μετά από έντονη
                            έκθεση στον ήλιο και εξαφανίζεται μέσα σε λίγες
                            ημέρες.
                          </p>
                          <div id="mountain" className=" ccm-block-styles">
                            <h2>ΟΡΕΙΝΟΣ ΗΛΙΟΣ</h2>
                            <p>
                              <strong>
                                Γιατί το δέρμα χρειάζεται ειδική φροντίδα σε
                                συνθήκες μεγάλου υψόμετρου;
                              </strong>
                            </p>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_01_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Για κάθε <span className="orange">1.000 μ</span>
                                . που ανεβαίνετε, οι ακτίνες του ήλιου αυξάνουν
                                σε ένταση έως και{" "}
                                <span className="orange">12%</span>
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_02_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Το φρέσκο χιόνι αντανακλά{" "}
                                <span className="orange">5</span> φορές
                                περισσότερη ακτινοβολία UV σε σχέση με τη στεγνή
                                άμμο της παραλίας.
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_03_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Έως και το <span className="orange">80%</span>{" "}
                                της ηλιακής ακτινοβολίας μπορεί να διαπεράσει
                                την αραιή νεφοκάλυψη.
                              </p>
                            </div>
                          </div>
                          <div className="ktsImageFloatingBlock">
                            <div className="imgWrapper">
                              <img
                                src="/assets/images/kts_img_320_04_sun.png"
                                width="320"
                                height="325"
                                alt
                              />
                            </div>
                            <div className="textWrapper">
                              <p>
                                Το κρύο, ο αέρας και η ξηρή ατμόσφαιρα ενδέχεται
                                να μειώσουν το επίπεδο ενυδάτωσης του δέρματος
                                κατά <span className="orange">13%</span>
                              </p>
                              <p className="footNote">
                                Πηγές: Global Solar UV Index, WHO and clinical
                                evaluation of the hydration level, 12 subjects
                              </p>
                              <div> </div>
                            </div>
                          </div>
                          <div id="HTMLBlock891" className="HTMLBlock">
                            <div
                              style={{
                                clear: "both"
                              }}
                            />
                          </div>
                          <p>
                            <strong>Το αποτέλεσμα</strong>
                            <br />
                            Το δέρμα αφυδατώνεται εύκολα. Γίνεται σφιχτό,
                            κόκκινο και ταλαιπωρημένο από τον ήλιο. Η άμυνα του
                            δέρματος απέναντι στο κρύο, τον άνεμο και τον έντονο
                            ήλιο απαιτεί ένα συγκεκριμένο τύπο προστασίας.
                          </p>
                          <p>
                            <strong>Χρησιμοποιήστε το σωστό αντηλιακό</strong>
                            <br />
                            Στα βουνά, το δέρμα σας απαιτεί ένα συγκεκριμένο
                            είδος προστασίας.
                            <br />
                            Ανατρέξτε στις γενικές συμβουλές μας για την ασφαλή
                            και αποτελεσματική εφαρμογή του αντηλιακού.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">ΑΣΦΑΛΕΙΣ ΚΑΤΩ ΑΠΟ ΤΟΝ ΗΛΙΟ</h2>
                        <p className="toggle__sub-title">
                          Τα αντηλιακά προϊόντα PIZ BUIN<sup>®</sup> σάς
                          προσφέρουν την αντηλιακή προστασία που χρειάζεστε,
                          αλλά τι άλλο μπορείτε να κάνετε για να είστε απολύτως
                          ασφαλείς κάτω από τον ήλιο;{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>ΠΡΟΣΤΑΣΙΑ ΑΠΟ ΤΟΝ ΗΛΙΟ</h2>
                          <p>
                            <strong>
                              Πώς λειτουργούν τα αντηλιακά φίλτρα;
                            </strong>
                            <br />Η βασική τεχνολογία που χρησιμοποιείται σε
                            κάθε αντηλιακό είναι το φίλτρο UV. Τα φίλτρα UV
                            είναι χημικά ή φυσικά.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/f47522e78906ab93bd6746d5c4ffa028_f95.png"
                              alt="kts_img_640_chemical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <p> </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            ΧΗΜΙΚΑ ΦΙΛΤΡΑ
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Τα χημικά φίλτρα λειτουργούν με την απορρόφηση του
                            υπεριώδους φωτός και τη μετατροπή του σε ακτίνες
                            μεγαλύτερου μήκους κύματος που είναι λιγότερο
                            επιβλαβείς για το δέρμα.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <p> </p>
                          <p
                            style={{
                              "-webkit-text-align": "left",
                              "text-align": "left"
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                "margin-left": "auto",
                                "margin-right": "auto"
                              }}
                              src="/assets/images/3abb2aaf80e1f6d050098a8ca6618a7a_f96.png"
                              alt="kts_img_640_psysical_filter.png"
                              width="320"
                              height="330"
                            />
                          </p>
                          <h3
                            className="orange"
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            ΦΥΣΙΚΑ ΦΙΛΤΡΑ
                          </h3>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            Τα φυσικά φίλτρα αντανακλούν, μεταδίδουν ή
                            απορροφούν εν μέρει το φως και προστατεύουν από την
                            ακτινοβολία UVA και UVB. Τα δύο πιο κοινά φυσικά
                            φίλτρα στα αντηλιακά είναι το διοξείδιο του τιτανίου
                            και το οξείδιο του ψευδαργύρου.
                          </p>
                          <p
                            style={{
                              "-webkit-text-align": "center",
                              "text-align": "center"
                            }}
                          >
                            {" "}
                          </p>
                          <h2>
                            ΤΙ ΣΗΜΑΙΝΕΙ Ο ΔΕΙΚΤΗΣ ΗΛΙΑΚΗΣ ΠΡΟΣΤΑΣΙΑΣ (SPF);
                          </h2>
                          <p>
                            Ο δείκτης ηλιακής προστασίας (SPF) που αναγράφεται
                            πάνω στη φιάλη του αντηλιακού σας, υποδεικνύει πόση
                            προστασία προσφέρει το αντηλιακό σας από τις ακτίνες
                            UVB.
                          </p>
                          <p>
                            Γενικά, όσο υψηλότερος είναι ο δείκτης προστασίας
                            του αντηλιακού σας, τόσο μεγαλύτερη είναι η
                            προστασία που παρέχεται, επομένως κάποιο αντηλιακό
                            με SPF 6 ή 10 προσφέρει χαμηλή προστασία, με SPF 15,
                            20 ή 25 προσφέρει μέση , με SPF 30 ή 50 προσφέρει
                            υψηλή ενώ με SPF 50+ προσφέρει ιδιαίτερα υψηλή
                            προστασία. Η μέτρηση πραγματοποιείται μέσω
                            τυποποιημένης δοκιμής.
                          </p>
                          <p>
                            Ο δείκτης SPF δείχνει πόσο περισσότερο μπορείτε να
                            παραμείνετε στον ήλιο χωρίς να προκληθεί έγκαυμα. Ο
                            τρόπος που λειτουργεί είναι ο εξής : παίρνετε τον
                            αριθμό των λεπτών που συνήθως χρειάζεται το δέρμα
                            σας για να καεί στον ήλιο χωρίς προστασία (ας πούμε
                            15 λεπτά). Στη συνέχεια, πολλαπλασιάστε τον αριθμό
                            αυτό με το δείκτη SPF (σε αυτή την περίπτωση, ο
                            δείκτης 6). Εφόσον 15 x 6 = 90, θα πρέπει να έχετε
                            90 λεπτά πριν αρχίσετε να καίγεστε από τον ήλιο. Με
                            άλλα λόγια, όσο υψηλότερος είναι ο SPF τόσο
                            μεγαλύτερη είναι η προστασία των αντηλιακών σας.
                            Ωστόσο, να θυμάστε ότι οι τιμές αυτές είναι
                            θεωρητικές. Στην πραγματικότητα, ο χρόνος εξαρτάται,
                            μεταξύ άλλων παραγόντων, από το φωτότυπο του
                            δέρματος σας και την ένταση της υπεριώδους
                            ακτινοβολίας στην περιοχή σας.
                          </p>
                          <p>
                            Για να επιλέξετε το σωστό SPF, θα πρέπει να
                            εξετάσετε διάφορους παράγοντες. Ο πιο σημαντικός από
                            αυτούς είναι το φωτότυπο του δέρματός σας, το οποίο
                            περιλαμβάνει το χρώμα του δέρματός σας πριν από την
                            έκθεση στον ήλιο, το χρώμα των μαλλιών και των
                            ματιών σας, καθώς και άλλους παράγοντες. Θα πρέπει
                            επίσης να σκεφτείτε πού θα πάτε διακοπές και ποια
                            εποχή του χρόνου θα είναι.
                          </p>
                          <h2> </h2>
                          <h2>ΤΙ ΣΗΜΑΙΝΕΙ «ΦΩΤΟΣΤΑΘΕΡΗ ΠΡΟΣΤΑΣΙΑ»;</h2>
                          <p>
                            Για την προστασία του δέρματος, τα αντηλιακά
                            χρησιμοποιούν φίλτρα UV που απορροφούν την
                            ακτινοβολία από τον ήλιο. Όταν ένα αντηλιακό
                            εκτίθεται σε αυτή την ακτινοβολία, μπορεί να
                            μεταβάλλει τη χημική δομή των φίλτρων UV,
                            υποβαθμίζοντας επομένως τις προστατευτικές
                            δυνατότητές τους.
                          </p>
                          <p>
                            Ένα «φωτοσταθερό» αντηλιακό είναι σε θέση να αντέξει
                            καλύτερα τις επιδράσεις της ακτινοβολίας UV,
                            επομένως διατηρεί το επίπεδο προστασίας του.
                          </p>
                          <p>
                            Όλα τα αντηλιακά προϊόντα PIZ BUIN<sup>®</sup>{" "}
                            προσφέρουν φωτοσταθερή προστασία.
                          </p>
                          <h2> </h2>
                          <h2>ΤΙ ΣΗΜΑΙΝΕΙ «ΠΡΟΣΤΑΣΙΑ ΕΥΡΕΟΣ ΦΑΣΜΑΤΟΣ»;</h2>
                          <p>
                            Ένα αντηλιακό με προστασία ευρέος φάσματος σημαίνει
                            ότι έχει την ικανότητα να προστατεύει από τις
                            βλαβερές συνέπειες τόσο των ακτίνων UVA (ακτίνες
                            γήρανσης) όσο και των ακτίνων UVB (οι ακτίνες που
                            προκαλούν έγκαυμα). Ένα αντηλιακό προϊόν, για να
                            ταξινομηθεί ότι προσφέρει προστασία ευρέος φάσματος,
                            πρέπει να απορροφά ή να ανακλά τουλάχιστον το 90%
                            της υπεριώδους ακτινοβολίας με εύρος μήκους κύματος
                            που κυμαίνεται από 290 έως 400 νανόμετρα (nm).
                          </p>
                          <p>
                            Όλα τα αντηλιακά προϊόντα PIZ BUIN<sup>®</sup>{" "}
                            προσφέρουν προστασία ευρέος φάσματος.
                          </p>
                          <h2> </h2>
                          <h2>ΑΝΤΟΧΗ ΣΤΟ ΝΕΡΟ</h2>
                          <p>
                            Είναι σημαντικό το αντηλιακό σας να είναι ανθεκτικό
                            στο νερό όταν κολυμπάτε, επειδή η υπεριώδης
                            ακτινοβολία, μισό μέτρο κάτω από την επιφάνεια του
                            νερού, εξακολουθεί να είναι 40% έντονη όσο και στην
                            επιφάνεια. Πρέπει επίσης να προστατεύετε το μέρος
                            του σώματός σας που είναι πάνω από την επιφάνεια,
                            καθώς το νερό αντανακλά περίπου το 25% της
                            υπεριώδους ακτινοβολίας και μπορεί να εντείνει τις
                            επιβλαβείς επιδράσεις.
                          </p>
                          <p>
                            Σχεδόν όλα τα αντηλιακά προϊόντα PIZ BUIN
                            <sup>®</sup> κυμαίνονται από ανθεκτικά σε έξτρα
                            ανθεκτικά στο νερό, όπως το ΝΕΟ PIZ BUIN WET SKIN
                            <sup>®</sup> Transparent Sun Spray.
                          </p>
                          <p className="footNote">
                            *Τα παρακάτω προϊόντα δεν είναι ανθεκτικά στο νερό:
                            Tan &amp; Protect Tan Intensifying Sun Spray SPF30,
                            In Sun Moisturising Sun Lotion SPF10
                          </p>
                          <h2> </h2>
                          <h2>ΣΥΜΒΟΥΛΕΣ ΓΙΑ ΑΣΦΑΛΕΙΑ ΣΤΟΝ ΗΛΙΟ</h2>
                          <p>
                            Η 1η εξόρμηση στην παραλία σημαίνει ότι το δέρμα σας
                            εκτίθεται για πρώτη φορά στον ήλιο, μετά από ένα
                            μακρύ χειμώνα κρύου καιρού και ζεστών ρούχων. Είναι
                            σημαντικό να δώσετε ιδιαίτερη προσοχή στο δέρμα σας
                            πριν φύγετε, για να διασφαλίσετε ότι είστε έτοιμοι
                            για την ξαφνική αλλαγή.
                          </p>
                          <h3> </h3>
                          <p>
                            <strong>
                              Συμβουλές για την ασφαλή και αποτελεσματική
                              εφαρμογή του αντηλιακού και τη στάση σας απέναντι
                              στον ήλιο:
                            </strong>
                          </p>
                          <ol>
                            <li>
                              <span>
                                Εφαρμόστε το αντηλιακό τουλάχιστον 20 λεπτά πριν
                                βγείτε στον ήλιο για να υπάρχει η μέγιστη δυνατή
                                απορρόφηση και προστασία.
                              </span>
                            </li>
                            <li>
                              <span>
                                Βεβαιωθείτε ότι εφαρμόζετε αρκετή ποσότητα
                                αντηλιακού. Η μη επαρκής ποσότητα περιορίζει
                                σημαντικά το επίπεδο προστασίας. Δώστε ιδιαίτερη
                                προσοχή στις πιο ευαίσθητες περιοχές, όπως
                                αυτιά, μύτη, ώμους, μπούστο και λαιμό. <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Μην αμελείτε τις περιοχές που δεν μπορείτε να
                                φτάσετε εύκολα καθώς και εκείνες που τις
                                ξεχνάτε, όπως τα αυτιά, τις πατούσες και την
                                πλάτη.
                              </span>
                            </li>
                            <li>
                              <span>
                                Επαναλάβετε τη χρήση του αντηλιακού κάθε 2 ώρες.
                                Εφαρμόστε πιο συχνά μετά από το κολύμπι, την
                                εφίδρωση ή το σκούπισμα με πετσέτα. <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Προσπαθήστε να αποφύγετε τον έντονο μεσημεριανό
                                ήλιο από τις 11:00 π.μ. μέχρι τις 3:00 μ.μ κατά
                                τους καλοκαιρινούς μήνες ή όταν βρίσκεστε σε
                                τροπικές χώρες. <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Εάν λαμβάνετε φαρμακευτική αγωγή, συμβουλευτείτε
                                τον ιατρό ή τον φαρμακοποιό σας, για να
                                βεβαιωθείτε ότι μπορείτε να κάθεστε ώρα στον
                                ήλιο. Ορισμένα φάρμακα ενδέχεται να κάνουν το
                                δέρμα πιο ευαίσθητο στις ακτίνες του ήλιου.{" "}
                                <br />
                              </span>
                            </li>
                            <li>
                              <span>
                                Η υπερβολική έκθεση στον ήλιο ενδέχεται να είναι
                                απειλητική για την υγεία σας, επομένως αποφύγετε
                                να μείνετε πολύ ώρα στον ήλιο, ακόμα και αν
                                χρησιμοποιείτε αντηλιακό.
                              </span>
                            </li>
                            <li>
                              <span>
                                Προστατέψτε τα παιδιά. Τα παιδιά είναι ιδιαίτερα
                                ευάλωτα στις επιδράσεις της υπεριώδους
                                ακτινοβολίας και συχνά περνούν περισσότερο χρόνο
                                σε εξωτερικούς χώρους σε σχέση με τους ενήλικες.
                                Οι γονείς θα πρέπει να φροντίζουν ιδιαίτερα για
                                την προστασία τους από τον ήλιο με προστατευτικά
                                ρούχα, καπέλα, γυαλιά ηλίου και αντηλιακό.
                                Κρατήστε τα βρέφη και τα μικρά παιδιά μακριά από
                                την άμεση έκθεση στο ηλιακό φως.
                              </span>
                            </li>
                          </ol>
                          <h2> </h2>
                          <h2>
                            <a name="skintypes" />
                            ΤΥΠΟΙ ΔΕΡΜΑΤΟΣ
                          </h2>
                          <p>
                            Οι διαφορετικοί τύποι δέρματος αντιδρούν στον ήλιο
                            με διαφορετικούς τρόπους, επομένως, ο τύπος του
                            δέρματος που έχετε, επηρεάζει τόσο το χρόνο που
                            μπορείτε να μείνετε στον ήλιο όσο και τα είδη των
                            αντηλιακών που χρειάζεστε.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                    <li>
                      <a href="#" className="toggle">
                        <h2 className="ktsHead">ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ</h2>
                        <p className="toggle__sub-title">
                          Τι άλλο θα πρέπει να γνωρίζω σχετικά με τη χρήση των
                          αντηλιακών προϊόντων PIZ BUIN<sup>®</sup>;{" "}
                          <span className="toggle__plus">+</span>
                        </p>
                      </a>
                      <ul>
                        <li>
                          <h2>
                            Η ΧΡΗΣΗ ΤΟΥ ΑΝΤΗΛΙΑΚΟΥ ΘΑ ΜΕ ΕΜΠΟΔΙΣΕΙ ΣΤΟ ΝΑ
                            ΜΑΥΡΙΣΩ;
                          </h2>
                          <p>
                            Μάλλον το αντίθετο συμβαίνει. Παρόλο που το χρώμα
                            του δέρματός σας μεταβάλλεται πιο αργά όταν φοράτε
                            αντηλιακό, το μαύρισμα θα είναι καλύτερο και
                            μεγαλύτερης διάρκειας, επειδή το αντηλιακό θα
                            προφυλάξει το δέρμα σας από το κάψιμο και το
                            ξεφλούδισμα. Ορισμένα προϊόντα PIZ BUIN<sup>®</sup>{" "}
                            περιέχουν ακόμη και ειδικά συστατικά που διεγείρουν
                            τη φυσική παραγωγή και την ομοιόμορφη κατανομή των
                            χρωστικών ουσιών μαυρίσματος στο δέρμα.
                          </p>
                          <p> </p>
                          <h2>
                            ΠΟΣΟ ΚΑΙΡΟ ΔΙΑΡΚΕΙ ΕΝΑ ΑΝΤΗΛΙΑΚΟ ΠΡΙΝ ΤΗ ΛΗΞΗ ΤΟΥ;
                          </h2>
                          <p>
                            Εξαρτάται από το εάν η φιάλη έχει ανοιχτεί ή όχι. Αν
                            δεν έχει ανοιχτεί, δεν υπάρχει κανένα πρόβλημα. Όλα
                            τα αντηλιακά PIZ BUIN<sup>®</sup> έχουν ελεγχθεί
                            σύμφωνα με τη νομοθεσία της ΕΕ και έχουν ωφέλιμη
                            διάρκεια ζωής περισσότερους από 30 μήνες. Αν η φιάλη
                            έχει ανοιχθεί για περισσότερους από 12 μήνες, θα
                            χρειαστεί να αγοράσετε νέα.
                          </p>
                          <p> </p>
                          <h2>
                            ΜΠΟΡΩ ΝΑ ΜΕΙΩΣΩ ΤΟ ΔΕΙΚΤΗ SPF ΚΑΘΩΣ ΠΕΡΝΩ
                            ΠΕΡΙΣΣΟΤΕΡΟ ΧΡΟΝΟ ΣΤΟΝ ΗΛΙΟ; ΠΟΣΟ ΓΡΗΓΟΡΑ;
                          </h2>
                          <p>
                            Μπορείτε να μειώσετε το επίπεδο προστασίας σας
                            σταδιακά κατά τη διάρκεια των διακοπών σας μόλις το
                            δέρμα σας έχει δημιουργήσει το δικό του σύστημα
                            άμυνας: το μαύρισμα. Μετά από μερικές ημέρες
                            αποτελεσματικής προστασίας, δοκιμάστε τη μετάβαση
                            από ένα προϊόν υψηλής προστασίας σε μεσαία. Ωστόσο
                            να θυμάστε ότι οι άνθρωποι με φυσικό μαύρισμα πρέπει
                            επίσης να χρησιμοποιούν αντηλιακό, καθώς το μαύρισμα
                            δεν προσφέρει επαρκή προστασία από την έκθεση στην
                            υπεριώδη ακτινοβολία. Επίσης λάβετε υπόψη ότι ο
                            δείκτης SPF που χρειάζεστε εξαρτάται από το φωτότυπο
                            του δέρματός σας και την ένταση της υπεριώδους
                            ακτινοβολίας στην περιοχή σας.
                          </p>
                          <p>
                            Αν δεν είστε σίγουροι σχετικά με το σωστό δείκτη SPF
                            για το αντηλιακό σας, πάντα επιλέγετε τον υψηλότερο.
                          </p>
                          <p> </p>
                        </li>
                      </ul>
                    </li>
                    <li className="clear">
                      <br />
                    </li>
                  </ul>
                </div>
              </section>
            </div>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\nif(document.location.hash != ''){\nvar anchor = document.location.hash;\n$(\"#container_thesun\").show();\nt = $(anchor);\n$('html,body').animate({scrollTop: t.offset().top},'slow');\n}\n});\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
